export const COORDINATES_MAP = {
  0: [6, 13],
  1: [6, 12],
  2: [6, 11],
  3: [6, 10],
  4: [6, 9],
  5: [5, 8],
  6: [4, 8],
  7: [3, 8],
  8: [2, 8],
  9: [1, 8],
  10: [0, 8],
  11: [0, 7],
  12: [0, 6],
  13: [1, 6],
  14: [2, 6],
  15: [3, 6],
  16: [4, 6],
  17: [5, 6],
  18: [6, 5],
  19: [6, 4],
  20: [6, 3],
  21: [6, 2],
  22: [6, 1],
  23: [6, 0],
  24: [7, 0],
  25: [8, 0],
  26: [8, 1],
  27: [8, 2],
  28: [8, 3],
  29: [8, 4],
  30: [8, 5],
  31: [9, 6],
  32: [10, 6],
  33: [11, 6],
  34: [12, 6],
  35: [13, 6],
  36: [14, 6],
  37: [14, 7],
  38: [14, 8],
  39: [13, 8],
  40: [12, 8],
  41: [11, 8],
  42: [10, 8],
  43: [9, 8],
  44: [8, 9],
  45: [8, 10],
  46: [8, 11],
  47: [8, 12],
  48: [8, 13],
  49: [8, 14],
  50: [7, 14],
  51: [6, 14],

  // BASE POSITIONS

  // P1
  100: [1.53, 10.57],
  101: [3.59, 10.57],
  102: [3.59, 12.43],
  103: [1.53, 12.43],

  // P2
  200: [1.53, 1.62],
  201: [3.59, 1.62],
  202: [3.59, 3.47],
  203: [1.53, 3.47],

  //P3
  300: [10.46, 1.62],
  301: [12.52, 1.62],
  302: [12.52, 3.47],
  303: [10.46, 3.47],

  // P4
  400: [10.46, 10.57],
  401: [12.52, 10.57],
  402: [12.52, 12.43],
  403: [10.46, 12.43],

  // HOME ENTRANCE
  //
  // P1
  500: [7, 13],
  501: [7, 12],
  502: [7, 11],
  503: [7, 10],
  504: [7, 9],
  505: [7, 8],

  // P2
  600: [1, 7],
  601: [2, 7],
  602: [3, 7],
  603: [4, 7],
  604: [5, 7],
  605: [6, 7],

  // P3
  700: [7, 1],
  701: [7, 2],
  702: [7, 3],
  703: [7, 4],
  704: [7, 5],
  705: [7, 6],

  //P4
  800: [13, 7],
  801: [12, 7],
  802: [11, 7],
  803: [10, 7],
  804: [9, 7],
  805: [8, 7],
};

export const STEP_LENGTH = 6.69;

export const PLAYERS = ["P1", "P2", "P3", "P4"];

export const BASE_POSITIONS = {
  P1: [100, 101, 102, 103],
  P2: [200, 201, 202, 203],
  P3: [300, 301, 302, 303],
  P4: [400, 401, 402, 403],
};

export const START_POSITIONS = {
  P1: 0,
  P2: 13,
  P3: 26,
  P4: 39,
};

export const HOME_ENTRANCE = {
  P1: [500, 501, 502, 503, 504, 505],
  P2: [600, 601, 602, 603, 604, 605],
  P3: [700, 701, 702, 703, 704, 705],
  P4: [800, 801, 802, 803, 804, 805],
};

export const HOME_POSITIONS = {
  P1: 505,
  P2: 605,
  P3: 705,
  P4: 805,
};

export const TURNING_POINTS = {
  P1: 50,
  P2: 11,
  P3: 24,
  P4: 37,
};

export const SAFE_POSITIONS = [0, 8, 13, 21, 26, 34, 39, 47];

export const STATE = {
  DICE_NOT_ROLLED: "DICE_NOT_ROLLED",
  DICE_ROLLED: "DICE_ROLLED",
};

export const PIECES = {
  P1: ["p11", "p12", "p13", "p14"],
  P2: ["p21", "p22", "p23", "p24"],
  P3: ["p31", "p32", "p33", "p34"],
  P4: ["p41", "p42", "p43", "p44"],
};
